<template>
  <div class="home-category">
    <div class="category-container">
      <ul class="menu">
      <li
        :class="{ active: current && current.id == item.id }"
        v-for="item in menuList"
        :key="item.id"
        @mouseenter="current = item"
      >
        <RouterLink :to="`/category/${item.id}`">{{ item.name }}</RouterLink>
        <template v-if="item.children?.length">
          <RouterLink
            v-for="subItem in item.children"
            :key="subItem.id"
            :to="`/category/sub/${subItem.id}`"
            >{{ subItem.name }}</RouterLink
          >
        </template>
        <!-- 骨架 start -->
        <template v-else>
          <XtxSkeleton
            animated="fade"
            width="60px"
            height="18px"
            bg="rgba(255,255,255,0.2)"
            style="margin-right: 5px"
          />
          <XtxSkeleton
            animated="fade"
            width="60px"
            height="18px"
            bg="rgba(255,255,255,0.2)"
          />
        </template>
        <!-- 骨架 end -->
      </li>
    </ul>
    <!--  左菜单栏hover弹窗 start  -->
    <div class="layer" v-if="current">
      <h4>分类商品推荐 <small>根据您的购买或浏览记录推荐</small></h4>
      <ul v-if="current.goods">
        <li v-for="item in current.goods" :key="item.id">
          <RouterLink to="/">
            <img :src="item.picture" alt="item.name" />
            <div class="info">
              <p class="name ellipsis-2">{{ item.name }}</p>
              <p class="desc ellipsis">{{ item.desc }}</p>
              <p class="price"><i>¥</i>{{ item.price }}</p>
            </div>
          </RouterLink>
        </li>
      </ul>
      <!-- 推荐 -->
      <ul v-if="current.brands">
        <li v-for="item in current.brands" :key="item.id">
          <RouterLink to="/">
            <img :src="item.picture" :alt="item.name" />
            <div class="info">
              <p class="place">
                <i class="iconfont icon-dingwei"></i>{{ item.place }}
              </p>
              <p class="name ellipsis">{{ item.name }}</p>
              <p class="desc ellipsis-2">{{ item.nameEn }}</p>
            </div>
          </RouterLink>
        </li>
      </ul>
    </div>
    <!--  左菜单栏hover弹窗 end  -->
    </div>
    
  </div>
</template>

<script>
import useMenuList from "@/hooks/home/useMenuList";
import { ref } from "vue";

export default {
  name: "HomeCategory",
  setup() {
    const menuList = useMenuList();
    // 定义当前移入的左菜单栏
    const current = ref(null);
    return { menuList, current };
  },
};
</script>
<style scoped lang="less">
.home-category {
  width: 250px;
  height: 500px;
  position: relative;
  z-index: 99;
  padding-top: 10px;
  box-sizing: border-box;
  .category-container {
    width: 250px;
    height: 480px;
    background: rgba(0, 0, 0, 0.8);
  }
  .menu {
    li {
      padding-left: 40px;
      height: 50px;
      line-height: 50px;
      &:hover,
      &.active {
        background-color: @xtxColor;
      }
      a {
        margin-right: 4px;
        color: #fff;
        &:first-child {
          font-size: 16px;
        }
      }
    }
  }

  .layer {
    width: 990px;
    height: 500px;
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 250px;
    top: 0;
    display: none;
    padding: 0 15px;
    h4 {
      font-size: 20px;
      font-weight: normal;
      line-height: 80px;
      small {
        font-size: 16px;
        color: #666;
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 310px;
        height: 120px;
        margin-right: 15px;
        margin-bottom: 15px;
        border: 1px solid #eee;
        border-radius: 4px;
        background: #fff;
        &:nth-child(3n) {
          margin-right: 0;
        }
        &.brand {
          height: 180px;
          a {
            align-items: flex-start;
            img {
              width: 120px;
              height: 160px;
            }
            .info {
              p {
                margin-top: 8px;
              }
              .place {
                color: #999;
              }
            }
          }
        }
        a {
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          padding: 10px;
          &:hover {
            background: #e3f9f4;
          }
          img {
            width: 95px;
            height: 95px;
          }
          .info {
            padding-left: 10px;
            line-height: 24px;
            width: 190px;
            .name {
              font-size: 16px;
              color: #666;
            }
            .desc {
              color: #999;
            }
            .price {
              font-size: 22px;
              color: @priceColor;
              i {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
  &:hover {
    .layer {
      display: block;
    }
  }
}
</style>
