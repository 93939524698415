<template>
  <div class="home-recharge">
    <h3 class="recharge-title">话费充值</h3>
    <div class="recharge-content">
      <form @submit="onAccountFormSubmit">
        <div class="form-item">
          <div class="input">
            <i class="iconfont icon-phone"></i>
            <input
              type="number"
              placeholder="请输入手机号码"
              v-model="rechargePhone"
            />
          </div>
        </div>
        <div class="form-item">
          <div class="input">
            <i class="iconfont icon-cart"></i>
            <input
              type="number"
              placeholder="请输入充值金额"
              v-model="rechargeMoney"
            />
          </div>
        </div>
        <button type="submit" class="recharge-btn" @click="handleRecharge">
          {{ isLogin ? "立即充值" : "点击登录后充值" }}
        </button>
      </form>
    </div>

    <h3 class="recharge-title">充值须知</h3>
    <div class="recharge-tips">
      <p>
        <text class="tips">充值到账时间：</text>话费充值通常在0到72小时内到账。
      </p>
      <p>
        <text class="tips">不支持的号码：</text
        >携号转网、副卡、虚拟号、集团号、空号等不支持充值，请确认后再下单，否则出现问题概不售后。
      </p>
      <p>
        <text class="tips">连续充值限制：</text
        >同一号码需在第一笔充值成功到账或失败后才能继续提交下一笔，禁止重复提交。
      </p>
      <p><text class="tips">客服热线: </text>400-6222680</p>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from 'vue-router'

const router = useRouter()
const isLogin = ref(false);
const rechargePhone = ref();
const rechargeMoney = ref();

const handleRecharge = () => {
	if(!isLogin.value) {
		console.log("请登录");
		router.push({
			path: '/login',
		})
	}
  console.log("跳转到充值页面");
};
</script>

<style scoped lang="less">
.home-recharge {
  width: 250px;
  height: 480px;
  background-color: #fff;
  position: absolute;
  right: 0;
  top: 10px;
  z-index: 98;
  padding: 15px;
  box-sizing: border-box;
}

.recharge-title {
  margin-bottom: 15px;
}

.recharge-content {
  margin: 10px 0;
}

.form {
  padding: 0 40px;
  &-item {
    margin-bottom: 20px;
    .input {
      position: relative;
      height: 36px;
      > i {
        width: 34px;
        height: 34px;
        background: #27ba9b;
        color: #fff;
        position: absolute;
        left: 1px;
        top: 1px;
        text-align: center;
        line-height: 34px;
        font-size: 18px;
      }
      input {
        padding-left: 44px;
        border: 1px solid #27ba9b;
        height: 36px;
        line-height: 36px;
        width: 100%;
        &.error {
          border-color: @priceColor;
        }
        &.active,
        &:focus {
          border-color: @xtxColor;
        }
        :focus-visible {
          outline: 0;
        }
      }
      input[type="number"] {
        -moz-appearance: textfield;
        &:focus-visible {
          outline: 0;
        }
      }
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .code {
        position: absolute;
        right: 1px;
        top: 1px;
        text-align: center;
        line-height: 34px;
        font-size: 14px;
        background: #af8c8c;
        color: #666;
        width: 90px;
        height: 34px;
        cursor: pointer;
      }
      .code.disabled {
        cursor: wait;
      }
    }
    > .error {
      position: absolute;
      font-size: 12px;
      line-height: 28px;
      color: @priceColor;
      i {
        font-size: 14px;
        margin-right: 2px;
      }
    }
  }
  .agree {
    a {
      color: #069;
    }
  }
  .btn {
    display: block;
    width: 100%;
    height: 40px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    background: @xtxColor;
    border: none;
    &.disabled {
      background: rgb(142, 185, 142);
    }
  }
}

.recharge-btn {
  width: 100%;
  margin-bottom: 20px;
  line-height: 35px;
  border: none;
  cursor: pointer;
  background-color: #27ba9b;
  color: #fff;
}

.recharge-tips {
  margin: 10px 0;
  p {
    font-size: 12px;
    text-align: left;
    margin-bottom: 10px;
    .tips {
      font-weight: bold;
      color: #27ba9b;
    }
  }
}
</style>
